class Cookies
{
    /**
     *
     * @param key
     * @param defaultValue
     * @returns {*}
     */
    get(key, defaultValue = null)
    {
        const cookies = document.cookie ? document.cookie.split('; ') : [];
        const l = cookies.length;
        let result = key ? undefined : {};
        let i = 0;
        let parts, name, value;

        for ( ; i < l ; i++ ) {
            parts = cookies[ i ].split('=');
            name = decodeURIComponent( parts.shift() );
            value = parts.join( '=' );

            if ( !key ) {
                result[ name ] = value;
            } else if ( key === name ) {
                if ( value === '' ) {
                    return value;
                }

                if ( value.indexOf('"') === 0 ) {
                    // This is a quoted cookie as according to RFC2068, unescape...
                    value = value.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
                }

                // Replace server-side written pluses with spaces.
                // If we can't decode the cookie, ignore it, it's unusable.
                // If we can't parse the cookie, ignore it, it's unusable.
                value = decodeURIComponent( value.replace(/\+/g, ' ' ) );

                try {
                    value = JSON.parse( value );
                } catch ( e ) { /* shhhhhh... */ }

                return value;
            }
        }

        if ( key && !result ) {
            return defaultValue;
        }

        return result;
    }

    /**
     *
     * @param key
     * @param val
     * @param options
     */
    set(key, val, options = {})
    {
        let time = options.expires;

        if ( typeof options.expires === 'number' ) {
            time = new Date();
            time.setMilliseconds( time.getMilliseconds() + options.expires * 864e+5 );
        }

        document.cookie = [
            encodeURIComponent( key ),
            '=',
            encodeURIComponent( JSON.stringify( val ) ),
            time ? '; expires=' + time.toUTCString() : '', // use expires attribute, max-age is not supported by IE
            options.path ? '; path=' + options.path : '',
            options.domain ? '; domain=' + options.domain : '',
            options.secure ? '; secure' : '',
        ].join('');
    }

    /**
     *
     * @param key
     * @returns {boolean}
     */
    has(key)
    {
        return this.get( key ) !== undefined;
    }

    /**
     *
     * @param key
     * @param options
     */
    remove(key, options)
    {
        this.set( key, '', Object.assign( true, {}, options, {
            expires: -1,
        } ) );
    }

    /**
     *
     * @returns {*}
     */
    all()
    {
        return this.get();
    }
}

const cookies = new Cookies();

export default function()
{
    return cookies;
}
