export default class OptionsBag
{
    #items = {};

    constructor(items = {})
    {
        this.#items = items;
    }

    delete(key)
    {
        delete this.#items[ key ];
    }

    get(key)
    {
        try {
            return this.#items[ key ];
        } catch ( err ) {
            return undefined;
        }
    }

    has(key)
    {
        return this.get( key ) !== undefined;
    }

    set(key, value)
    {
        this.#items[ key ] = value;
    }

    append(key, value)
    {
        this.set( key, value );
    }

    keys()
    {
        return Object.keys( this.#items );
    }

    entries()
    {
        return Object.entries( this.#items );
    }

    forEach(callbackFn, thisArg)
    {
        thisArg = thisArg || this;

        Object.entries( this.#items ).forEach( callbackFn, thisArg );
    }

    values()
    {
        return Object.values( this.#items );
    }

    all()
    {
        return this.#items;
    }

    copy()
    {
        return new OptionsBag( this.#items );
    }

    merge(newOptions = {})
    {
        this.#items = Object.assign( this.#items, newOptions );

        return this;
    }
}
