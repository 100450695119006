import aos from 'aos';
import {fadeHide, fadeShow, fadeToggle, slideHide, slideShow} from "./lib/animate";
import unlessElementExists from "./lib/utils/unlessElementExists.js";
import onEachElements from "./lib/utils/onEachElements.js";
import addEventListeners from "./lib/utils/addEventListeners.js";
import {createApp} from "vue";
import StraylightGallery from "./components/StraylightGallery.vue";
import StraylightScrollTo from "./components/StraylightScrollTo.vue";
import StraylightFormContact from "./components/StraylightFormContact.vue";
import StraylightMotion from "./components/StraylightMotion.vue";
import httpClient from "./lib/http";
import {useLocalStorage} from "./lib/storage";
import useCookies from "./lib/cookies";
import gdpr from "./config/gdpr.js";
import {Modal, typhoon} from "./lib/typhoon";
import getFieldsValues from "./lib/utils/getFieldsValues.js";

httpClient( {
    options : {
        XMLHttpRequest: true,
    },
    headers : {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
} );

unlessElementExists('.app', container => {
    createApp({
        components: {
            StraylightGallery,
            StraylightScrollTo,
            StraylightFormContact,
            StraylightMotion,
        },
        mounted() {
            aos.init();
            typhoon();

            unlessElementExists('.nav', nav => {
                onEachElements( nav, '.nav-item', item => {
                    unlessElementExists( item, '.nav-dropdown', dropdown => {
                        addEventListeners( item, {
                            mouseenter() {
                                window.innerWidth >= 768 && slideShow( dropdown, 150);
                            },
                            mouseleave() {
                                window.innerWidth >= 768 && slideHide( dropdown, 150);
                            },
                        } );
                    } );
                } );

                let last_pos_y = 0;

                const headerScrollSpy = function () {
                    if ( window.scrollY >= 100 ) {
                        nav.classList.add('is-fixed');

                        if ( window.scrollY > last_pos_y ) {
                            nav.classList.add('is-hidden');
                        } else {
                            nav.classList.remove('is-hidden');
                        }
                    } else {
                        nav.classList.remove('is-fixed');
                        nav.classList.remove('is-hidden');
                    }

                    last_pos_y = window.scrollY;
                };

                headerScrollSpy();

                addEventListeners( window, {
                    scroll: headerScrollSpy
                } );
            } );

            unlessElementExists('#menu', menu => {
                const checkBreakPoints = function () {
                    if ( window.innerWidth <= 1024 ) {
                        menu.style.display = 'none';
                        menu.style.opacity = '0';
                    } else {
                        menu.style.display = 'block';
                        menu.style.opacity = '1';
                    }
                };

                checkBreakPoints();
                window.addEventListener( 'resize', checkBreakPoints );

                unlessElementExists('#openMenu', button => {
                    let menu_state = false;

                    addEventListeners( button, {
                        click() {
                            menu_state = !menu_state;

                            fadeToggle( menu, 150 ).then( () => {
                                menu_state ? document.body.classList.add('menu-opened') : document.body.classList.remove('menu-opened');
                            } );
                        }
                    } );
                } );
            } );

            unlessElementExists('#cookiesNotice', notice => {
                const storage = useLocalStorage();
                const cookies = useCookies();

                function handleChoices(choices) {
                    Object.entries( gdpr.categories ).forEach( ([name,values]) => {
                        if ( choices[ name ] === true && values.apps !== null ) {

                            values.apps.forEach( app => {
                                if ( gdpr.apps[ app ] ) {
                                    unlessElementExists( '#' + gdpr.apps[ app ].id, el => {
                                        if ( el.tagName.toLowerCase() === 'script' ) {
                                            if ( el.hasAttribute('data-src') ) {
                                                el.onload = () => typeof gdpr.apps[ app ].callback === 'function' && gdpr.apps[ app ].callback( el );
                                                el.type = el.getAttribute('data-type');
                                                el.src = el.getAttribute('data-src');
                                            } else {
                                                const newScriptTag = document.createElement('script');
                                                newScriptTag.innerHTML = el.innerHTML;
                                                typeof gdpr.apps[ app ].callback === 'function' && gdpr.apps[ app ].callback( newScriptTag );
                                                el.parentNode.insertBefore( newScriptTag, el );
                                                el.parentNode.removeChild( el );
                                            }
                                        }

                                        if ( el.tagName.toLowerCase() === 'iframe' ) {
                                            typeof gdpr.apps[ app ].callback === 'function' && gdpr.apps[ app ].callback( el );
                                            el.src = el.getAttribute('data-src');
                                        }
                                    } );
                                }
                            } );
                        }
                    } );

                    unlessElementExists('#cookiesModal', modal => {
                        unlessElementExists(modal, '#cookiesForm', form => {
                            Object.entries( choices ).forEach( ([name,value]) => {
                                const checkbox = form.querySelector('input[type=checkbox][name='+ name +']');

                                if ( value && checkbox ) {
                                    checkbox.setAttribute('checked', true );
                                }
                            } );
                        } );
                    } );
                }

                function saveChoices(choices) {
                    storage.set('cookies.bar', 'set' );
                    cookies.set('cookies_settings', choices, {secure: location.protocol === 'https:' });

                    handleChoices( choices );
                }

                if ( !storage.has('cookies.bar') ) {
                    setTimeout( () => fadeShow( notice, 300 ), 0 );
                }

                const categories = gdpr.categories;

                const default_choices = Object.fromEntries(
                    Object.entries( categories ).map( ([name,values]) => [ name, values.default ] )
                );

                handleChoices(
                    cookies.get('cookies_settings', default_choices )
                );

                notice.querySelector('#cookiesAcceptBtn').addEventListener('click', () => {
                    saveChoices( Object.fromEntries(
                        Object.entries( categories ).map( ([name,values]) => [ name, true ] )
                    ) );

                    fadeHide( notice, 300 );
                } );

                notice.querySelector('#cookiesRefuseBtn').addEventListener('click', () => {
                    saveChoices( default_choices );

                    fadeHide( notice, 300 );
                } );

                unlessElementExists('#cookiesModal', modal => {
                    unlessElementExists( modal, '#cookiesForm', form => {
                        form.addEventListener('submit', e => {
                            e.preventDefault();

                            saveChoices( getFieldsValues( form ) );

                            fadeHide( notice, 300 );
                            Modal.getInstanceOrCreate( modal ).hide();
                        } );
                    } );
                } );
            } );
        }
    }).mount( container );
} );