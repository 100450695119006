/**
 * @param {object} object
 * @returns {string}
 */
export default function (object)
{
    return Object.entries( object ).map( ( [ key, value ] ) => {
        return encodeURIComponent( key ) + '=' + encodeURIComponent( value );
    } ).join('&');
}
