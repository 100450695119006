<script setup>
    import {ref} from "vue";

    const root = ref();

    const handleClick = () => {
        const el = root.value;

        const nextSection = el.closest('body').querySelector('section');

        nextSection.scrollIntoView({behavior: "smooth"});
    }
</script>

<template>
    <div class="absolute left-1/2 -translate-x-1/2 bottom-10">
        <button @click="handleClick" ref="root"
                class="text-whitesmoke text-6xl text-center animate-bounce">
            <i class="fa-fw fa-solid fa-chevron-down"></i>
        </button>
    </div>
</template>