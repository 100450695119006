<script setup>
import {onMounted, ref} from "vue";
import httpClient from "../lib/http/index.js";

const props = defineProps(['inputs']);

const http = httpClient();

const isOnSubmit = ref( false );
const formSubmitted = ref( false );
const formSuccess = ref( null );

async function handleSubmit(e) {
    isOnSubmit.value = true;

    e.preventDefault();

    const form = e.target;

    const data = Object.fromEntries(
        [ ...form.elements ]
            .map( item => [ item.name, item.value ] )
            .filter( ( [ key ] ) => key.length > 0 )
    );

    data.recaptcha = await grecaptcha.execute('6Lfik40qAAAAAC8DFVqRMhZ1lfRJC3AdT-0aDcvI', {action: 'CONTACT'});

    const response = await http.post('/api/contact', data );
    const body = response.body;

    isOnSubmit.value = false;
    formSubmitted.value = true;

    formSuccess.value = typeof body.success === "boolean" && body.success === true;

    if ( formSuccess.value ) {
        form.reset();
    }

    setTimeout( function () {
        formSubmitted.value = false;
        formSuccess.value = null;
    }, 1000 * 10 );
}
</script>

<template>
    <form class="bg-brown p-5 rounded" @submit="handleSubmit">
        <slot></slot>

        <label for="input_name" class="block mb-2.5 text-whitesmoke">{{ props.inputs.name.label }}</label>
        <input type="text" id="input_name" name="name" :placeholder="props.inputs.name.placeholder" required
               class="bg-smoke rounded font-sans text-base block p-2.5 w-full mb-5">

        <label for="input_email" class="block mb-2.5 text-whitesmoke">{{ props.inputs.email.label }}</label>
        <input type="email" id="input_email" name="email" :placeholder="props.inputs.email.placeholder" required
               class="bg-smoke rounded font-sans text-base block p-2.5 w-full mb-5">

        <label for="input_subject" class="block mb-2.5 text-whitesmoke">{{ props.inputs.subject.label }}</label>
        <input type="text" id="input_subject" name="subject" :placeholder="props.inputs.subject.placeholder" required
               class="bg-smoke rounded font-sans text-base block p-2.5 w-full mb-5">

        <label for="input_message" class="block mb-2.5 text-whitesmoke">{{ props.inputs.message.label }}</label>
        <textarea id="input_message" name="message" :placeholder="props.inputs.message.placeholder" required
                  class="bg-smoke rounded font-sans text-base block p-2.5 w-full h-64 mb-5"></textarea>

        <button type="submit" :disabled="isOnSubmit"
                class="py-2.5 px-6 w-full bg-salmon text-white text-lg font-sans rounded transition-colors duration-150 hover:bg-redviolet">
            {{ props.inputs.submit }} <i class="ml-2.5 fa-regular fa-paper-plane"></i>
        </button>

        <div :class="{
            'hidden': !formSubmitted && formSuccess === null || formSubmitted && formSuccess === false,
            'block': formSubmitted && formSuccess === true,
        }">
            <div class="mt-5 p-2.5 bg-emerald-600 rounded text-gray-100 flex flex-row gap-x-2.5">
                <span class="text-2xl flex flex-col place-content-center">
                    <i class="fa-fw fa-solid fa-check"></i>
                </span>
                    <span>
                    Votre message nous a bien été envoyé. Nous y répondrons dans les plus brefs délais !
                </span>
            </div>
        </div>

        <div :class="{
            'hidden': !formSubmitted && formSuccess === null || formSubmitted && formSuccess === true,
            'block': formSubmitted && formSuccess === false,
        }">
            <div class="mt-5 p-2.5 bg-rose-500 rounded text-gray-100 flex flex-row gap-x-2.5">
                <span class="text-2xl flex flex-col place-content-center">
                    <i class="fa-fw fa-solid fa-triangle-exclamation"></i>
                </span>
                    <span>
                    Une erreur s'est produite lors de l'envoie du formulaire. Veuillez réessayez.
                </span>
            </div>
        </div>
    </form>
</template>