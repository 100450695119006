export default class HeadersBag extends Headers
{
    #headers;

    constructor(headers)
    {
        super({});

        if ( !( headers instanceof Headers ) ) {
            headers = new Headers( headers );
        }

        this.#headers = headers;
    }

    delete(key)
    {
        this.#headers.delete( key );
    }

    get(key)
    {
        return this.#headers.get( key );
    }

    has(key)
    {
        return this.#headers.has( key );
    }

    set(key, value)
    {
        this.#headers.set( key, value );
    }

    append(key, value)
    {
        this.#headers.append( key, value );
    }

    keys()
    {
        return this.#headers.keys();
    }

    entries()
    {
        return this.#headers.entries();
    }

    forEach(callbackFn, thisArg)
    {
        thisArg = thisArg || this;

        this.#headers.forEach( callbackFn, thisArg );
    }

    values()
    {
        return this.#headers.values();
    }

    all()
    {
        const buffer = {};

        for ( const pair of this.#headers.entries() ) {
            buffer[ pair[0] ] = pair[1];
        }

        return buffer;
    }

    object()
    {
        return this.#headers;
    }

    copy()
    {
        return new HeadersBag( this.#headers );
    }

    merge(newHeaders = {})
    {
        const headers = Object.assign( this.all(), newHeaders );

        this.#headers = new Headers( headers );

        return this;
    }
}
