import OptionsBag from "./OptionsBag";
import HeadersBag from "./HeadersBag";
import Response from "./Response";
import ResponseError from "./ResponseError";
import abortTimeout from "../utils/abortTimeout";
import serialize from "../utils/serialize";
import {isClass, isPlainObject} from "../utils/is";

function validateStatus(status)
{
    return status >= 200 && status <= 299
}

function isRequestWithBody(method)
{
    return ['POST', 'PUT', 'PATCH'].includes( method.toUpperCase() );
}

function isForm(obj)
{
    return isClass( obj, 'FormData' );
}

function objectToFormData(obj)
{
    if ( isForm( obj ) ) {
        return obj;
    }

    obj = Object.entries( obj ).reduce( ( form, [ key, value ] ) => {
        if ( typeof value === 'number' ) {
            value = value.toString();
        }

        if ( !( typeof value === 'string' ) ) {
            value = JSON.stringify( value );
        }

        form.append( key, value );

        return form;
    }, new FormData() );

    return obj;
}

async function makeXMLHttpRequestHandler(method, url, data, headers, options = {})
{
    const timeout = options.timeout ? abortTimeout( options.timeout ) : undefined;
    const signal = options.signal || timeout || undefined;
    const validate = options.validate || validateStatus;
    const responseType = options.responseType || '';

    const config = {
        method,
        url,
        data,
        headers,
        options
    };

    const buildResponse = function (body, status, statusText, headers) {
        const contentType = headers.get('Content-Type');

        if ( contentType && typeof body === 'string' ) {
            if ( contentType.includes('json') ) {
                body = JSON.parse( body );
            }

            if ( contentType.includes('image') ) {
                body = new Blob( body );
            }

            if ( contentType.includes('video') || contentType.includes('audio') ) {
                throw 'Video and Audio can only be received if the option `responseType` is set on `arraybuffer`';
            }
        }

        return new Response(
            body,
            status,
            statusText,
            headers,
            config,
        );
    };

    const stringToHeaders = function (string) {
        let headers;

        if ( string.length === 0 ) {
            headers = {};
        } else {
            headers = string.trim().split(/[\r\n]+/)
                .reduce( ( acc, line) => {
                    const parts = line.split(": ");
                    const header = parts.shift();
                    acc[ header ] = parts;
                    return acc;
                }, {} );
        }

        return new HeadersBag( headers );
    }

    return await new Promise( ( resolve, reject ) => {
        const xhr = new XMLHttpRequest();

        xhr.open( method, url, true );
        xhr.responseType = responseType.toLowerCase();

        if ( isPlainObject( data ) ) {
            headers.set('Content-Type', 'application/json');
            data = JSON.stringify( data );
        }

        if ( isForm( data ) ) {
            headers.delete('Content-Type');
        }

        for ( const pair of headers.entries() ) {
            xhr.setRequestHeader( pair[0], pair[1] );
        }

        const onLoadCallback = () => {
            const response = xhr.responseType === '' || xhr.responseType === 'text' ? xhr.responseText : xhr.response;
            const responseBag = buildResponse( response, xhr.status, xhr.statusText, stringToHeaders( xhr.getAllResponseHeaders() ) );

            if ( validate( xhr.status ) ) {
                resolve( responseBag );
            } else {
                reject( new ResponseError( xhr.status, xhr.statusText, responseBag ) );
            }
        };

        const onErrorCallback = () => {
            const response = xhr.responseType === '' || xhr.responseType === 'text' ? xhr.responseText : xhr.response;
            const responseBag = buildResponse( response, xhr.status, xhr.statusText, stringToHeaders( xhr.getAllResponseHeaders() ) );

            reject( new ResponseError( xhr.status, xhr.statusText, responseBag ) );
        };

        const onAbortCallback = () => {
            const response = xhr.responseType === '' || xhr.responseType === 'text' ? xhr.responseText : xhr.response;
            const responseBag = buildResponse( response, 0, signal.reason, new HeadersBag );

            reject( new ResponseError( 0, signal.reason, responseBag ) );
        };

        xhr.addEventListener( 'load', onLoadCallback );

        xhr.addEventListener( 'error', onErrorCallback );
        xhr.upload.addEventListener( 'error', onErrorCallback );

        xhr.addEventListener( 'abort', onAbortCallback );
        xhr.upload.addEventListener( 'abort', onAbortCallback );

        const onDownloadProgressCallback = options.onDownloadProgress || undefined;
        const onUploadProgressCallback = options.onUploadProgress || undefined;

        onDownloadProgressCallback && xhr.addEventListener( 'progress', onDownloadProgressCallback )
        onUploadProgressCallback && xhr.upload.addEventListener( 'progress', onUploadProgressCallback )

        signal && signal.addEventListener('abort', () => {
            xhr.abort();
        } );

        if ( isRequestWithBody( method ) && data ) {
            xhr.send( data );
        } else {
            xhr.send();
        }
    } );
}

async function makeFetchHandler(method, url, data, headers, options = {})
{
    const timeout = options.timeout ? abortTimeout( options.timeout ) : undefined;
    const signal = options.signal || timeout || undefined;
    const mode = options.mode || 'cors';
    const cache = options.cache || 'default';
    const validate = options.validate || validateStatus;

    const config = {
        method,
        url,
        data,
        headers,
        options
    };

    const buildResponse = function (body, status, statusText, headers) {
        return new Response(
            body,
            status,
            statusText,
            new HeadersBag( headers ),
            config,
        );
    };

    return await new Promise( ( resolve, reject ) => {
        try {
            if ( isPlainObject( data ) ) {
                headers.set('Content-Type', 'application/json');
                data = JSON.stringify( data );
            }

            if ( isForm( data ) ) {
                headers.delete('Content-Type');
            }

            headers = headers.all();

            const options = {
                method,
                headers,
                signal,
                mode,
                cache,
            };

            if ( isRequestWithBody( method ) ) {
                options['body'] = data;
            }

            fetch( url, options )
                .then( response => {
                    const contentType = response.headers.get('Content-Type');

                    const wrapResponse = async body => {
                        return await new Promise(resolve => {
                            resolve( buildResponse( body, response.status, response.statusText, response.headers ) );
                        } );
                    };

                    if ( contentType.includes('image', 0 ) ) {
                        return response.blob().then( wrapResponse );
                    }

                    if ( contentType.includes('video', 0 ) || contentType.includes('audio', 0 ) ) {
                        return response.arrayBuffer().then( wrapResponse );
                    }

                    if ( contentType.includes('json') ) {
                        return response.json().then( wrapResponse );
                    }

                    return response.text().then( wrapResponse );
                } )
                .then( response => {
                    if ( validate( response.status ) ) {
                        resolve( response );
                    } else {
                        reject( new ResponseError( response.status, response.statusText, response ) )
                    }
                } )
                .catch( reason => {
                    reject( new ResponseError( 0, reason, buildResponse( '', 0, '', {} ) ) );
                } )
        } catch (e) {
            reject( new ResponseError( 0, e, buildResponse( '', 0, '', {} ) ) );
        }
    } );
}

function makeRequestHandler(useXMLHttpRequest = false)
{
    if ( window.fetch && !useXMLHttpRequest ) {
        return makeFetchHandler;
    } else {
        return makeXMLHttpRequestHandler;
    }
}

const baseHeaders = new HeadersBag( new Headers() );
const baseOptions = new OptionsBag();

class Http
{
    get(url, headers = {}, options = {})
    {
        return this.request( 'GET', url, {}, headers, options );
    }

    post(url, data = {}, headers = {}, options = {})
    {
        return this.request( 'POST', url, data, headers, options );
    }

    postForm(url, data = {}, headers = {}, options = {})
    {
        return this.request( 'POST', url, objectToFormData( data ), headers, options );
    }

    put(url, data = {}, headers = {}, options = {})
    {
        return this.request( 'PUT', url, data, headers, options );
    }

    putForm(url, data = {}, headers = {}, options = {})
    {
        return this.request( 'PUT', url, objectToFormData( data ), headers, options );
    }

    patch(url, data = {}, headers = {}, options = {})
    {
        return this.request( 'PATCH', url, data, headers, options );
    }

    patchForm(url, data = {}, headers = {}, options = {})
    {
        return this.request( 'PATCH', url, objectToFormData( data ), headers, options );
    }

    delete(url, data = {}, headers = {}, options = {})
    {
        return this.request( 'DELETE', url, data, headers, options );
    }

    head(url, headers = {}, options = {})
    {
        return this.request( 'HEAD', url, {}, headers, options );
    }

    request(method, url, data = {}, headers = {}, options = {})
    {
        headers = baseHeaders.copy().merge( headers );
        options = baseOptions.copy().merge( options ).all();

        const useXMLHttpRequest = options.onUploadProgress || options.onDownloadProgress || options.XMLHttpRequest || false;

        const requestHandler = makeRequestHandler( useXMLHttpRequest );

        return requestHandler( method, url, data, headers, options );
    }

    async all(...requests)
    {
        return await Promise.all( requests );
    }

    async race(...requests)
    {
        return await Promise.race( requests );
    }

    abort()
    {
        return new AbortController();
    }

    url( uri, params = {} )
    {
        let queryString;

        if ( Array.isArray( params ) ) {
            queryString = params.map( key => {
                return encodeURIComponent( key )
            } ).join('&');
        }

        if ( typeof params === "object" ) {
            queryString = serialize( params );
        }

        return uri + ( queryString !== '' ? '?' + queryString : '' );
    }

    get headers()
    {
        return baseHeaders;
    }

    get options()
    {
        return baseOptions;
    }

    withHeaders(headers)
    {
        Object.entries( headers ).forEach( ( [ key, value ] ) => {
            baseHeaders.append( key, value );
        } );
    }

    withOptions(options)
    {
        Object.entries( options ).forEach( ( [ key, value ] ) => {
            baseOptions.append( key, value );
        } );
    }
}

const http = new Http;

export default function(config = {})
{
    http.withHeaders( config ? ( config.headers || {} ) : {} );
    http.withOptions( config ? ( config.options || {} ) : {} );

    return http;
}
