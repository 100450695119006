export default class Response
{
    #body;
    #status;
    #statusText;
    #headers;
    #config;

    constructor(body, status, statusText, headers, config)
    {
        this.#body = body;
        this.#status = status;
        this.#statusText = statusText;
        this.#headers = headers;
        this.#config = config;
    }

    get body()
    {
        return this.#body;
    }

    get status()
    {
        return this.#status;
    }

    get statusText()
    {
        return this.#statusText;
    }

    get headers()
    {
        return this.#headers;
    }

    get config()
    {
        return this.#config;
    }
}
