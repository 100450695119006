<script setup>
import {onMounted, ref} from "vue";
import gsap from 'gsap';
import easingFunctions from "../lib/animate/easingFunctions.js";

const container = ref();
const content = ref();
const filler = ref();

let timeline = null;

const easeOutQuint = easingFunctions.easeInOutQuint( 0.22, .95, 0.3, .95 );

const observerVariable = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach( ( entry ) => {
            const tm = gsap.timeline();
            tm.pause();

            if ( entry.isIntersecting ) {
                const content_width = content.value.clientWidth;

                const tl = gsap.timeline();

                tl.to( filler.value, { duration: .5, ease: easeOutQuint, width: content_width })
                    .to( filler.value, { duration: .8, ease: easeOutQuint, left: content_width }, '#start')
                    .to( filler.value, { duration: .8, ease: easeOutQuint, width: '0%' }, '#start')
                    .to( content.value, { duration: .1, ease: easeOutQuint, opacity: 1 }, '#start')
                    .to( container.value, { duration: .1, ease: easeOutQuint, width: '100%' })

                tm.add( tl, 0 );

                tm.play();
                timeline = tm;
            } else {
                if ( timeline ) {
                    timeline.restart().pause();
                }
            }
        });
    },
);

onMounted( () => {
    observerVariable.observe( container.value );
} );


</script>

<template>
    <div class="relative" ref="container">
        <div class="opacity-0" ref="content">
            <slot></slot>
        </div>

        <div class="block absolute top-0 left-0 bottom-0 w-0 bg-brown" ref="filler"></div>
    </div>
</template>