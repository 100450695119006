const toString = Object.prototype.toString;

/**
 * @param obj
 * @returns {boolean}
 */
export function isObject(obj)
{
    return obj !== null && typeof obj === 'object'
}

/**
 * @param obj
 * @param className
 * @returns {boolean}
 */
export function isClass(obj, className)
{
    return isObject( obj ) && toString.call( obj ) === '[object ' + className + ']';
}

/**
 * @param obj
 * @returns {boolean}
 */
export function isPlainObject(obj)
{
    return isObject( obj ) && isClass( obj, 'Object' );
}

/**
 * @param obj
 * @returns {boolean}
 */
export function isFunction(obj)
{
    return isObject( obj ) && isClass( obj, 'Function' );
}

/**
 * @param value
 * @returns {boolean}
 */
export function isInteger(value)
{
    if ( Number.isInteger ) {
        return Number.isInteger( value );
    } else {
        return typeof value === 'number' && isFinite( value ) && Math.floor( value ) === value;
    }
}
