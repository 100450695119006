export default class ResponseError
{
    #status;
    #statusText;
    #response;

    constructor(status, statusText, response)
    {
        this.#status = status;
        this.#statusText = statusText;
        this.#response = response;
    }

    get status()
    {
        return this.#status;
    }

    get statusText()
    {
        return this.#statusText;
    }

    get response()
    {
        return this.#response;
    }
}
