export default {
    apps: {
        googleTagManager: {
            id: "google-tag-manager",
            callback: null,
        },

        googleNoScript: {
            id: "google-tag-manager-noscript",
            callback: null,
        },
    },

    categories: {
        required: {
            default: true,
            apps: null,
        },

        analytics: {
            default: false,
            apps: [
                "googleTagManager", "googleNoScript"
            ],
        },

        thirdParty: {
            default: false,
            apps: [
            ],
        }
    },
};