<script setup>
import {onMounted, ref} from "vue";
import getElementSize from "../lib/utils/getElementSize.js";
import addEventListeners from "../lib/utils/addEventListeners.js";

const props = defineProps(['images']);

const fixedContainer = ref();
const sizeableContainer = ref();
const elementTemplate = ref();

const translatable_distance = ref(0);
const image_width = ref(0);

const nbr_of_dots = ref(0);
const current_index = ref(0);

const min_windows_width = 540;

function handlePrevClick() {
    let new_value = current_index.value - 1;

    if ( new_value < 0 ) {
        new_value = nbr_of_dots.value - 1;
    }

    current_index.value = new_value;
    translateTo( current_index.value );
}

function handleNextClick() {
    let new_value = current_index.value + 1;

    if ( new_value === nbr_of_dots.value ) {
        new_value = 0;
    }

    current_index.value = new_value;
    translateTo( current_index.value );
}

function handleDotClick(new_index) {
    current_index.value = new_index;
    translateTo( current_index.value );
}

function translateTo(new_index) {
    sizeableContainer.value.style.transform = 'translateX(-' + translatable_distance.value * new_index + 'px)';
}

function computeSliderWidth() {
    if ( !props.images ) {
        return;
    }

    const compute_gap = ( props.images.length - 1 ) * 20;
    const width = getElementSize( elementTemplate.value ).width;
    const max_width = width * props.images.length;

    sizeableContainer.value.style.width = max_width + compute_gap + 'px';

    image_width.value = width;

    if ( window.innerWidth <= min_windows_width ) {
        nbr_of_dots.value = props.images.length;
        translatable_distance.value = width + 20;
    } else {
        translatable_distance.value = ( width * 2 ) + 40;
        nbr_of_dots.value = Math.floor( max_width / translatable_distance.value ) + 1;
    }

    current_index.value = 0;
    translateTo( current_index.value );
}

onMounted( computeSliderWidth );
window.addEventListener('resize', computeSliderWidth );

const current_image_index = ref(null);

let remove_escape_listener = null;

function handlePrevImageClick() {
    let new_value = current_image_index.value - 1;

    if ( new_value < 0 ) {
        new_value = props.images.length - 1;
    }

    current_image_index.value = new_value;
}

function handleNextImageClick() {
    let new_value = current_image_index.value + 1;

    if ( new_value === props.images.length ) {
        new_value = 0;
    }

    current_image_index.value = new_value;
}

function handleCloseModalImage() {
    current_image_index.value = null;
    document.body.classList.remove('overflow-hidden');
    remove_escape_listener.keyup();
}

function handleModalImage(index) {
    current_image_index.value = index;
    document.body.classList.add('overflow-hidden');

    remove_escape_listener = addEventListeners( document, {
        keyup(event) {
            if ( event.key === 'Escape' ) {
                handleCloseModalImage();
            }

            if ( event.key === 'ArrowLeft' ) {
                handlePrevImageClick();
            }

            if ( event.key === 'ArrowRight' ) {
                handleNextImageClick();
            }
        }
    } );
}
</script>

<template>
    <div v-if="props.images">
        <div class="overflow-hidden mb-5">
            <div class="container mx-auto px-2.5" ref="fixedContainer">
                <div class="flex flex-row flex-nowrap gap-x-5 transition-transform duration-300 will-change-transform" ref="sizeableContainer">
                    <button v-for="($image, index) in props.images"
                            @click="() => handleModalImage( index )"
                        class="inline-block h-80 bg-cover bg-center"
                        :style="{backgroundImage: 'url(' + $image['sizes']['medium_large'] + ')', width: image_width + 'px'}">
                    </button>
                </div>

                <div class="w-5/12 min-w-[240px] max-w-[480px] h-px" ref="elementTemplate"></div>
            </div>
        </div>

        <div class="container mx-auto px-2.5 relative" v-if="nbr_of_dots > 1">
            <div class="w-2/12 flex flex-row flex-nowrap gap-x-1 relative z-10">
                <button @click="handlePrevClick"
                        class="bg-salmon border border-salmon text-whitesmoke text-xl rounded px-4 py-0.5 transition-colors hover:bg-white hover:text-salmon">
                    <i class="fa-solid fa-arrow-left"></i>
                </button>

                <button @click="handleNextClick"
                    class="bg-salmon border border-salmon text-whitesmoke text-xl rounded px-4 py-0.5 transition-colors hover:bg-white hover:text-salmon">
                    <i class="fa-solid fa-arrow-right"></i>
                </button>
            </div>

            <ul class="w-full absolute inset-0 hidden sm:flex flex-row flex-nowrap gap-x-1 justify-center">
                <li v-for="i in nbr_of_dots" class="flex flex-col place-content-center">
                    <button @click="() => handleDotClick( i - 1 )"
                        class="block w-2.5 h-2.5 rounded-full"
                        :class="[ {
                            'bg-redviolet': i - 1 === current_index,
                            'bg-salmon': i - 1 !== current_index
                        } ]"
                    ></button>
                </li>
            </ul>
        </div>
    </div>

    <Teleport to="body">
        <Transition>
            <div v-if="current_image_index !== null" class="bg-black/65 fixed inset-0 z-50">
                <button class="text-whitesmoke text-3xl absolute right-5 top-5" @click="handleCloseModalImage">
                    <i class="fa-solid fa-xmark"></i>
                </button>
                <button class="text-whitesmoke text-3xl absolute left-5 top-1/2 -translate-y-1/2" @click="handlePrevImageClick">
                    <i class="fa-solid fa-chevron-left"></i>
                </button>
                <button class="text-whitesmoke text-3xl absolute right-5 top-1/2 -translate-y-1/2" @click="handleNextImageClick">
                    <i class="fa-solid fa-chevron-right"></i>
                </button>
                <div class="flex flex-col place-content-center justify-center w-full h-full">
                    <img :src="props.images[ current_image_index ]['sizes']['2048x2048']" alt="" class="block w-auto max-w-screen h-auto max-h-screen py-10 px-12 mx-auto">
                </div>
            </div>
        </Transition>
    </Teleport>
</template>